import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { FiArrowLeft } from "react-icons/fi"

const Back = ({ color }) => {
	return (
		<div className="lg:hidden">
			<AniLink
				cover
				to={`/`}
				direction="down"
				duration={1.3}
				bg="#2d3748"
				className="z-20 fixed bottom-0 right-0 mr-5 mb-5 z-10 bg-gray-300 lg:bg-transparent shadow lg:shadow-none rounded-full"
			>
				<FiArrowLeft className="text-5xl " color="#2d3748" />
			</AniLink>
		</div>
	)
}

export default Back
