import React, { useState } from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { Spring } from "react-burgers"
import { Link } from "react-scroll"
import { useSpring, animated, config } from "react-spring"

const Navigation = ({ contact, home, active }) => {
	const [toggle, toggleToggle] = useState(false)

	const animation = useSpring({
		opacity: toggle ? 1 : 0,
		x: toggle ? 0 : 20,
		height: toggle ? 25 : 0,
		from: { opacity: 0, x: 20, height: 0 },
		config: config.default,
	})

	const inactiveSite = slug => {
		return (
			<animated.div
				className="pr-3 lato-nav uppercase text-right underline tracking-widest"
				style={animation}
			>
				{slug}
			</animated.div>
		)
	}

	const activeSite = (to, slug) => {
		return (
			<animated.div
				className="text-right"
				style={animation}
			>
				<AniLink
					cover
					to={to}
					direction="down"
					duration={1.3}
					bg="#2d3748"
					className="pr-3 cursor-pointer lato-nav tracking-widest hover:text-gray-500"
				>
					{slug}
				</AniLink>
			</animated.div>
		)
	}

	const burger = () => {
		return (
			<div className="p-3 bg-white lg:bg-transparent fixed top-0 right-0 z-10">
				<div className="">
					<div className="flex justify-end mx-2 my-2">
						<Spring
							className="p-0"
							color="#2d3748"
							width={30}
							lineSpacing={8}
							borderRadius={2}
							active={toggle}
							onClick={() => toggleToggle(!toggle)}
						/>
					</div>
					{toggle ? (
						<div className="">
							{active === "home"
								? inactiveSite("Home")
								: activeSite("/", "Home")}
							{active === "tech"
								? inactiveSite("Tech")
								: activeSite("/tech", "Tech")}
							{active === "tools"
								? inactiveSite("Philosophie")
								: activeSite("/philosophie", "Philosophie")}
							{active === "projects"
								? inactiveSite("Referenzen")
								: activeSite("/referenzen", "Referenzen")}

							{contact ? (
								<animated.div
									className="pr-3 cursor-pointer lato-nav tracking-widest text-right hover:text-gray-500"
									style={animation}
								>
									<Link
										activeClass="active"
										to="Contact"
										spy={true}
										smooth={true}
										offset={-80}
										duration={2000}
									>
										Kontakt
									</Link>
								</animated.div>
							) : null}
						</div>
					) : null}
				</div>
			</div>
		)
	}

	return <div className="hidden lg:block">{burger()}</div>
}

export default Navigation
