import React from "react"
import { Link } from "gatsby"

const Footer = () => (
	<footer className="bg-transparent h-48 absolute bottom-0 w-full">
		<div className="w-full h-24"></div>
		<div className="w-full text-center text-sm text-gray-700 mb-1">© Becker und Zietz 2019</div>
		<div className="w-full text-center text-gray-800 mb-1">info@beckerundzietz.com</div>
		<div className="w-full flex justify-center items-center mb-3">
			<div className="mx-2 text-right text-sm text-gray-700"><Link to="/datenschutz">Datenschutzerklärung</Link></div>
			<div className="mx-2 text-right text-sm text-gray-700"><Link to="/impressum">Impressum</Link></div>
		</div>
	</footer>
)

export default Footer
